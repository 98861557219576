import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ky from 'ky';

import { useSession } from '../context';
import { API_HOST } from '../utils';

export const useIdentityImportMutation = () => {
  const client = useQueryClient();
  const { context } = useSession();

  const mutationFn = useCallback(async ({ file }: { file: File }) => {
    const form = new FormData();
    form.append('file', file);
    const response = await ky.post(`${API_HOST}/api/identities/upload`, {
      body: form,
      headers: {
        Authorization: `Bearer ${context}`,
        'ngrok-skip-browser-warning': 'any',
      },
    });

    const body = await response.json();
    return body;
  }, [context]);

  return useMutation({
    mutationFn,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['identities'] })
    },
  });
}
