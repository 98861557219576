import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useSession } from '../context';
import { API_HOST } from '../utils';

export const useOidcConfigMutation = () => {
  const client = useQueryClient();
  const { context } = useSession();

  const mutationFn = useCallback(async (config: any) => {
    const response = await fetch(`${API_HOST}/api/config/oidc`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${context}`,
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'any',
      },
      body: JSON.stringify(config),
    });
    const body = await response.json();
    return body;
  }, [context]);

  return useMutation({
    mutationFn,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['oidc-config'] })
    },
  });
}
