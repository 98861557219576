import React, { useCallback, useState } from 'react';
import { Button, Pagination, Table } from '@bigcommerce/big-design';

import { useIdentityQuery, useIdentityDeleteMutation, useStoreHash } from '../hooks';
import { Loading } from './Loading';

export const Identities = () => {
  const storeHash = useStoreHash();
  const [ page, setPage ] = useState<number>(1);
  const [ currentPage, setCurrentPage ] = useState<number>(1);
  const { mutateAsync: remove } = useIdentityDeleteMutation();
  const {
    isLoading,
    data,
    fetchNextPage,
    fetchPreviousPage,
  } = useIdentityQuery();
  const { items, meta }: any = data?.pages[page - 1] || { items: [], meta: {} };
  const perPage = meta?.perPage || 25;

  const updatePage = useCallback(async (fetchPage: number) => {
    if (fetchPage > page) {
      await fetchNextPage();
    } else {
      await fetchPreviousPage();
    }

    setPage(fetchPage);
    setCurrentPage(fetchPage);
  }, [
    page,
    fetchNextPage,
    fetchPreviousPage,
  ]);

  if (isLoading) <Loading />

  return (
    <>
      <Pagination
        currentPage={currentPage}
        itemsPerPage={perPage}
        itemsPerPageOptions={[perPage]}
        onItemsPerPageChange={() => {}}
        onPageChange={(newPage) => updatePage(newPage)}
        totalItems={meta?.count || 0}
      />
      <Table
        columns={[
          {
            header: 'BigCommerce Customer ID',
            hash: 'bcId',
            render: ({ bcId }) => (
              <a
                href={`https://store-${storeHash}.mybigcommerce.com/manage/customers/${bcId}/edit`}
                target="_blank"
              >{bcId}</a>
            ),
          },
          {
            header: 'Identity Provider ID',
            hash: 'idpId',
            render: ({ idpId }) => idpId,
          },
          {
            header: 'Actions',
            hash: 'id',
            render: ({ id }) => (
              <Button
                actionType="destructive"
                onClick={() =>{
                  if (!id) return;
                  remove({ id: id as number })
                }}
              >
                Delete
              </Button>
            ),
          },
        ]}
        items={items}
        stickyHeader
      />
       <Pagination
        currentPage={currentPage}
        itemsPerPage={perPage}
        itemsPerPageOptions={[perPage]}
        onItemsPerPageChange={() => {}}
        onPageChange={(newPage) => updatePage(newPage)}
        totalItems={meta?.count || 0}
      />
    </>
  )
}
