import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useCallback } from 'react';
import ky from 'ky';

import { useSession } from '../context';
import { API_HOST } from '../utils';

export const useIdentityQuery = () => {
  const { context } = useSession();

  const queryFn = useCallback(async ({ pageParam = 0 }) => {
    const response = await ky.get(`${API_HOST}/api/identities`, {
      headers: {
        Authorization: `Bearer ${context}`,
        'ngrok-skip-browser-warning': 'any',
      },
      searchParams: {
        page: pageParam
      },
    });
    const body = await response.json();
    return body;
  }, [context])

  const query = useInfiniteQuery({
    queryKey: ['identities'],
    queryFn,
    getNextPageParam: (lastPage: any) => {
      const { perPage, page, count } = lastPage.meta;
      const hasNextPage = (page + 1) * perPage < count;
      const next = hasNextPage ? lastPage.meta.page + 1 : undefined;
      return next;
    },
    getPreviousPageParam: (prevPage: any) => {
      let prev: number | undefined = prevPage.meta.page - 1
      prev = prev < 0 ? undefined : prev;
      return prev;
    },
  });

  // refetch if the authentication token changes
  useEffect(() => {
    query.refetch();
  }, [
    query.refetch,
    context,
  ]);

  return query;
}
