import { useQuery } from '@tanstack/react-query';
import { useEffect, useCallback } from 'react';

import { useSession } from '../context';
import { API_HOST } from '../utils';

export const useOidcConfigQuery = () => {
  const { context } = useSession();

  const queryFn = useCallback(async () => {
    const response = await fetch(`${API_HOST}/api/config/oidc`, {
      headers: {
        Authorization: `Bearer ${context}`,
        'ngrok-skip-browser-warning': 'any',
      },
    });
    const body = await response.json();
    return body;
  }, [context])

  const query = useQuery({
    queryKey: ['oidc-config'],
    queryFn,
    staleTime: 3 * 60 * 1000,
  });

  // refetch if the authentication token changes
  useEffect(() => {
    query.refetch();
  }, [
    query.refetch,
    context,
  ]);

  return query;
}
