import React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import { bigCommerceSDK } from '../utils';

const SessionContext = createContext({ context: '' });

const SessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const search = useLocation().search;
  const [context, setContext] = useState('');

  useEffect(() => {

    if (!search) return;

    const params = new URLSearchParams(search);
    const context = params.get('context');
    if (context) {
      setContext(context.toString());
      // Keeps app in sync with BC (e.g. heatbeat, user logout, etc)
      bigCommerceSDK(context);
    }
  }, [search]);

  return (
    <SessionContext.Provider value={{ context }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);

export default SessionProvider;
