import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ky from 'ky';

import { useSession } from '../context';
import { API_HOST } from '../utils';

export const useIdentityDeleteMutation = () => {
  const client = useQueryClient();
  const { context } = useSession();

  const mutationFn = useCallback(async ({ id }: { id: number }) => {
    const response = await ky.delete(`${API_HOST}/api/identities/${id}`, {
      headers: {
        Authorization: `Bearer ${context}`,
        'ngrok-skip-browser-warning': 'any',
      },
    });

    const body = await response.json();
    return body;
  }, [context]);

  return useMutation({
    mutationFn,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['identities'],
        exact: false,
      });
    },
  });
}
