import React from 'react';
import { InlineMessage } from '@bigcommerce/big-design';

export const Error = () => (
  <InlineMessage
    marginTop="large"
    type="error"
    header="Something Went Wrong!"
    messages={[
      {
        text: 'An error occurred when attempting to contact our servers. Please contact support.'
      }
    ]}
  />
)
