import React from 'react';
import { Text, Box, H4 } from '@bigcommerce/big-design';

import { useConfigQuery } from '../hooks';
import { Loading } from './Loading';
import { Error } from './Error';

export interface ConfigureIDPProps {}

export const ConfigureIDP: React.FC<ConfigureIDPProps> = () => {
  const { data = {}, isLoading, isError } = useConfigQuery();

  if (isLoading) return <Loading />
  if (isError) return <Error />

  return (
    <>
      <Text>
        In order for login to work you'll need to add the following "Redirect
        URIs" to you're SSO IDP.

        <H4 marginTop="medium">Login Callback</H4>
        <Box
          backgroundColor="secondary20"
          border="box"
          display="inline-block"
          padding="small"
        >
          {data.loginCallback}
        </Box>

        <H4 marginTop="medium">Logout Callback</H4>
        <Box
          backgroundColor="secondary20"
          border="box"
          display="inline-block"
          padding="small"
        >
          {data.logoutCallback}
        </Box>
      </Text>
    </>
  );
}
