import React, { useState } from 'react';
import { Tabs, Box } from '@bigcommerce/big-design';

import { Identities } from './Identities';
import { UploadForm } from './UploadForm';

export const ImportOrManage = () => {
  const [activeTab, setActiveTab] = useState('option-1');

  return (
    <>
      <Tabs
        activeTab={activeTab}
        items={[
          {
            id: 'option-1',
            title: 'Import CSV',
          },
          {
            id: 'option-2',
            title: 'Manage User Connections',
          },
        ]}
        onTabClick={setActiveTab}
        style={{
          marginBottom: 20
        }}
      />
      <Box marginTop="large">
        {activeTab === 'option-1' && <UploadForm/>}
        {activeTab === 'option-2' && <Identities/>}
      </Box>
    </>
  )
}
