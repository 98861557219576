import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InlineMessage,
  Text,
} from '@bigcommerce/big-design';

import { useIdentityImportMutation } from '../hooks';

export const UploadForm = () => {
  const fileRef = useRef<{ files: File[] }>();
  const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();
  const { mutateAsync: mutate } = useIdentityImportMutation();
  const upload = useCallback(async () => {
    const files: File[] | undefined = fileRef?.current?.files;
    if (files && files?.length > 0) {
      setIsUploading(true);
      const result: any = await mutate({ file: files[0] });
      if (result.success === true) {
        setIsSuccessOpen(true);
        setErrors(undefined);
      } else if (result.errors.length > 0) {
        const errs = result.errors[0].children.map((error: any) => ({
          header: `Row "${parseInt(error.property, 10) + 1}"`,
          messages: error.children.reduce((acc: string[], child: any) => {
              const validationErrors = Object.values(child.constraints);
              validationErrors.forEach((err) => {
                acc.push(err as string);
              });
              return acc;
            }, [])
        }));
        setIsSuccessOpen(false);
        setErrors(errs);
      } else {
        setIsSuccessOpen(false);
        setErrors([{
          header: `Error Saving Import`,
          messages: [{
            text: `There was an error saving your import. Please verify your
            your import file is correct and try again. If the issue persists
            please contact support.`
          }]
        }])
      }

      setIsUploading(false);
    }
  }, []);

  return (
    <>
      <div style={{ maxWidth: 700, marginBottom: 20 }}>
        <Text>
          If you have existing customers in your store prior to configuring OIDC
          Connector you'll need to import them before they'll be able to login.
        </Text>
      </div>
      <div style={{ maxWidth: 700, marginBottom: 20 }}>
        <Text>
          First you should import them into your Identity Provider (IDP) which
          will generate a unique ID for them. Then you'll need to upload a CSV
          file that maps their BigCommerce Customer ID to the new ID in your
          IDP. You can download a template CSV by clicking the button below.
          <b> Please note</b> that the file is <i>comma</i> delimited (not tab).
        </Text>

        <Button
          variant="secondary"
          onClick={() => {
            window.open(`${window.location.origin}/identity-import-template.csv`, `_blank`)
          }}
        >Download CSV Template</Button>
      </div>

      <div style={{ maxWidth: 700, marginBottom: 20 }}>
        <Text>
          Once imported you can use view and manage your connected users by
          clicking on "Manage User Connections".
        </Text>
      </div>
      <Form>
        <FormGroup>
          <Input
            // @ts-ignore
            ref={fileRef}
            type="file"
            label="CSV Upload"
            required
          />
        </FormGroup>

        <Button
          disabled={isUploading}
          isLoading={isUploading}
          onClick={async (e) => {
            e.preventDefault();
            await upload();
          }}
        >Upload</Button>
      </Form>

      {isSuccessOpen && (
        <InlineMessage
          marginTop="large"
          header="Successfully Imported!"
          messages={[
            {
              text: `The CSV was imported successfully. You can click on "Manage
              User Connections" to view all of your existing connected users.
              `
            }
          ]}
          onClose={() => setIsSuccessOpen(false)}
        />
      )}
      {errors && errors.length > 0 && errors.map((err: any, idx: number) => (
        <InlineMessage
          marginTop="large"
          type="error"
          header={`Errors Importing ${err.header}`}
          messages={err.messages.map((msg: string) => ({
            text: `    - ${msg}`,
          }))}
          onClose={() => {
            const newErrors = errors.filter((_e: any, idxx: number) => {
              return idx !== idxx;
            });
            setErrors(newErrors);
          }}
        />
      ))}
    </>
  )
}
