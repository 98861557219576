import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InlineMessage,
} from '@bigcommerce/big-design';

import {
  useOidcConfigQuery,
  useOidcConfigMutation,
} from '../hooks';
import { Loading } from './Loading';
import { Error } from './Error';

export const ConfigForm: React.FC<{}> = ({}) => {
  const { data, isLoading, isError } = useOidcConfigQuery();
  const { mutateAsync: mutate } = useOidcConfigMutation();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
  const [tokenEndpoint, setTokenEndpoint] = useState<string>('');
  const [authorizationEndpoint, setAuthorizationEndpoint] = useState<string>('');
  const [registrationEndpoint, setRegistrationEndpoint] = useState<string>('');
  const [endSessionEndpoint, setEndSessionEndpoint] = useState<string>('');
  const [accountEndpoint, setAccountEndpoint] = useState<string>('');
  const [resetPasswordEndpoint, setResetPasswordEndpoint] = useState<string>('');
  const [clientId, setClientId] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>('');
  const setConfig = useCallback((config: any) => {
    setTokenEndpoint(config.tokenEndpoint);
    setAuthorizationEndpoint(config.authorizationEndpoint);
    setRegistrationEndpoint(config.registrationEndpoint);
    setEndSessionEndpoint(config.endSessionEndpoint);
    setAccountEndpoint(config.accountEndpoint);
    setResetPasswordEndpoint(config.resetPasswordEndpoint);
    setClientId(config.clientId);
    setClientSecret(config.clientSecret);
  }, []);

  useEffect(() => {
    if (data) {
      setConfig(data);
    }
  }, [
    JSON.stringify(data),
  ]);


  if (isLoading) {
    return <Loading/>
  }

  if (isError) {
    return <Error/>
  }

  return (
    <>
      <Form>
        <FormGroup>
          <Input
            label="Client ID"
            type="text"
            onChange={({ target: { value } }) => {
              setClientId(value);
              setIsDirty(true);
            }}
            value={clientId}
            required
          />
        </FormGroup>
        <FormGroup>
          <Input
            label="Client Secret"
            type="password"
            onChange={({ target: { value } }) => {
              setClientSecret(value);
              setIsDirty(true);
            }}
            value={clientSecret}
            required={false}
          />
        </FormGroup>
        <FormGroup>
          <Input
            label="Token Endpoint"
            type="url"
            onChange={({ target: { value } }) => {
              setTokenEndpoint(value);
              setIsDirty(true);
            }}
            value={tokenEndpoint}
            required
          />
        </FormGroup>
        <FormGroup>
          <Input
            label="Authorization Endpoint"
            type="url"
            onChange={({ target: { value } }) => {
              setAuthorizationEndpoint(value);
              setIsDirty(true);
            }}
            value={authorizationEndpoint}
            required
          />
        </FormGroup>
        <FormGroup>
          <Input
            label="Registration Endpoint"
            type="url"
            onChange={({ target: { value } }) => {
              setRegistrationEndpoint(value);
              setIsDirty(true);
            }}
            value={registrationEndpoint}
            required
          />
        </FormGroup>
        <FormGroup>
          <Input
            label="End Session Endpoint"
            type="url"
            onChange={({ target: { value } }) => {
              setEndSessionEndpoint(value);
              setIsDirty(true);
            }}
            value={endSessionEndpoint}
            required
          />
        </FormGroup>
        <FormGroup>
          <Input
            label="Account Endpoint"
            type="url"
            onChange={({ target: { value } }) => {
              setAccountEndpoint(value);
              setIsDirty(true);
            }}
            value={accountEndpoint}
            required
          />
        </FormGroup>
        <FormGroup>
          <Input
            label="Reset Password Endpoint"
            type="url"
            onChange={({ target: { value } }) => {
              setResetPasswordEndpoint(value);
              setIsDirty(true);
            }}
            value={resetPasswordEndpoint}
            required
          />
        </FormGroup>

        <Button
          variant="secondary"
          disabled={!isDirty}
          onClick={() => setConfig(data)}
        >
          Cancel
        </Button>
        <Button
          disabled={!isDirty}
          isLoading={isSaving}
          onClick={async (e) => {
            e.preventDefault();
            setIsSaving(true)
            const result = await mutate({
              tokenEndpoint,
              authorizationEndpoint,
              registrationEndpoint,
              endSessionEndpoint,
              accountEndpoint,
              resetPasswordEndpoint,
              clientId,
              clientSecret,
            });
            setIsDirty(false);
            setIsSaving(false);
            setIsSuccessOpen(true);
          }}
        >
          Save
        </Button>
      </Form>

      {isSuccessOpen && (
        <InlineMessage
          marginTop="large"
          header="Successfully Saved!"
          messages={[
            {
              text: `You configuration was successfully updated. If you haven't
              yet, please complete the rest of the setup steps.
              `
            }
          ]}
          onClose={() => setIsSuccessOpen(false)}
        />
      )}
    </>
  );
}
