import React, { useState } from 'react';
import {
  Box,
  Flex,
  FlexItem,
  ProgressCircle,
  Tabs,
  Text,
} from '@bigcommerce/big-design';
import {
  ConfigForm,
  ConfigureIDP,
  ImportOrManage,
} from './components';
import { useSession } from './context';

function App() {
  const [activeTab, setActiveTab] = useState('step-1');
  const { context } = useSession();

  if (!context) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        style={{
          height: '75vh'
        }}
      >
        <FlexItem>
          <ProgressCircle size="large" />
        </FlexItem>
        <FlexItem>
          <Text>Authorizing... Please wait.</Text>
        </FlexItem>
      </Flex>
    )
  }

  return (
    <div>
      <Tabs
        activeTab={activeTab}
        items={[
          {
            id: 'step-1',
            title: '1. OIDC Settings',
          },
          {
            id: 'step-2',
            title: '2. Configure Your IDP',
          },
          {
            id: 'step-3',
            title: '3. Import/Manage Connected Users (Optional)',
          },
        ]}
        onTabClick={setActiveTab}
        style={{
          marginBottom: 20
        }}
      />
      <Box marginTop="large">
        {activeTab === 'step-1' && <ConfigForm/>}
        {activeTab === 'step-2' && <ConfigureIDP/>}
        {activeTab === 'step-3' && <ImportOrManage/>}
      </Box>
    </div>
  );
}

export default App;
