import React from 'react';

import { Flex, ProgressCircle } from '@bigcommerce/big-design';

export const Loading = () => (
  <Flex
    justifyContent="center"
    alignItems="center"
    style={{
      height: '75vh'
    }}
  >
    <ProgressCircle size="large" />
  </Flex>
)
